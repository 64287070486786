<div>
    <div class="row">
        <div *ngIf="loading" class="d-flex justify-content-center align-items-center m-auto">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="row">
        <input #fileInput (change)="changeUrl($event)" [hidden]="true" type="file">
        <ng-container *ngIf="editing">
            <button (click)="fileInput.click()" class="btn btn-link m-auto" type="submit" i18n>
                Cambiar foto
            </button>
        </ng-container>
    </div>
</div>
