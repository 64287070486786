import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserHttpService} from '../../../services/http/user-http.service';
import {UserHttpModel} from '../../../models/http/UserHttpModel';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {UserDataSources} from '../../../models/user-datasources';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';

interface FormProperty {
    name: string;
    type: string;
    label: string;
    placeholder: string;
    defaultValue: string;
    editable: boolean;
    value: any;
    required: boolean;
    minValue?: number;
    patternValidation?: string;
    selectable?: boolean;
    dataSource?: string;
    autocomplete?: boolean;
    autocompleteOptions?: string;
    autocompleteInitValue?: string;
    wrapperClass?: string;
    valueChanges?: () => void;
}

@Component({
    selector: 'app-create-form-util',
    templateUrl: './create-form-util.component.html',
    styleUrls: ['./create-form-util.component.scss']
})
export class CreateFormUtilComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    constructor(
        private userHttpService: UserHttpService,
        private route: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar
    ) {
    }

    private _properties: FormProperty[] = [
        {
            name: 'name',
            type: 'string',
            label: $localize`Nombre`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-6'
        },
        {
            name: 'lastName',
            type: 'string',
            label: $localize`Apellidos`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-6'
        },
        {
            name: 'dni',
            type: 'string',
            label: $localize`DNI`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-4',
            patternValidation: '^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$',
            valueChanges: () => {
                if (!this.model.dni) {
                    return;
                }

                this.model.dni = this.model.dni.toUpperCase();
                this.formGroup.get('dni').setValue(this.model.dni, {emitEvent: false});
            }
        },
        {
            name: 'phone',
            type: 'string',
            label: $localize`Número de teléfono`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-4',
            patternValidation: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{0,8}$',
        },
        {
            name: 'email',
            type: 'email',
            label: $localize`Email`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-4'
        },
        {
            name: 'isAdmin',
            type: 'boolean',
            label: 'Administrador',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-4'
        }
    ];

    get properties(): FormProperty[] {
        return this._properties;
    }

    set properties(value: FormProperty[]) {
        this._properties = value;
    }

    private _model = new UserHttpModel();

    get model(): UserHttpModel {
        return this._model;
    }

    set model(value: UserHttpModel) {
        this._model = value;
    }

    private _loading = false;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    private _formGroup: FormGroup;

    get formGroup(): FormGroup {
        return this._formGroup;
    }

    set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    ngOnInit() {
        this.initFormGroup();
    }


    /**
     * Init form group
     */
    initFormGroup() {
        const formControls = {};
        for (const property of this.properties) {
            if (!property.editable) {
                continue;
            }

            formControls[property.name] = [property.defaultValue, []];

            // Add validator if required
            if (property.required) {
                formControls[property.name][1].push(Validators.required);
            }

            // Add email validator
            if (property.type === 'email') {
                formControls[property.name][1].push(Validators.email);
            }

            // Add number validator
            if (property.type === 'number') {
                formControls[property.name][1].push(Validators.min(property.minValue));
            }

            // Add pattern validator if string
            if (property.type === 'string' && property.patternValidation) {
                formControls[property.name][1].push(Validators.pattern(property.patternValidation));
            }
        }

        this.formGroup = this.formBuilder.group(formControls);

        for (const property of this.properties) {
            this.subscriptions.push(this.formGroup.get(property.name).valueChanges.subscribe(value => {
                    this.model[property.name] = value;

                    if (property.valueChanges) {
                        property.valueChanges();
                    }
                })
            );
        }
    }

    /**
     * Form events
     */
    onSaveClick() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        this.saveModel();
    }

    saveModel() {
        this.loading = true;
        this.userHttpService.create(this.model).subscribe(
            (ok) => {
                this.snackBar.open($localize`El usuario se ha guardado correctamente.`, $localize`Aceptar`, {duration: 5000});
                this.route.navigate(['user', ok.id]);
            },
            (ko) => {
                this.loading = false;
                this.snackBar.open($localize`Ha ocurrido un error guardando el usuario.`, $localize`Aceptar`, {duration: 5000});
                throw ko;
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
