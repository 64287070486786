<div class="card">
    <div class="card-header">
        <strong>Usuario:</strong> {{this.model.name}} {{this.model.lastName}}
    </div>

    <div class="card-footer">
        <ng-container >
            <button class="btn btn-sm btn-primary mr-2" routerLink="/user/{{this.model.id}}/edit" type="button"><i
                class="icon-pencil icons"></i>
                Editar usuario
            </button>
        </ng-container>
        <button class="btn btn-sm btn-secondary mr-2" routerLink="/user" type="button"><i
            class="icon-action-undo icons"></i>
            Volver
        </button>
    </div>
</div>
