import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';
import {LoginPageComponent} from './components/pages/login-page/login-page.component';
import {LoginFormUtilComponent} from './components/utils/login-form-util/login-form-util.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {RecoveryFormUtilComponent} from './components/utils/recovery-form-util/recovery-form-util.component';
import {RecoveryPageComponent} from './components/pages/recovery-page/recovery-page.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {PasswordUpdatePageComponent} from './components/pages/password-update-page/password-update-page.component';
import {PasswordUpdateFormComponent} from './components/utils/password-update-form/password-update-form.component';
import {LoginLayoutComponent} from './layouts/login-layout/login-layout.component';

@NgModule({
    declarations: [LoginPageComponent, LoginFormUtilComponent, RecoveryFormUtilComponent, RecoveryPageComponent, PasswordUpdatePageComponent, PasswordUpdateFormComponent, LoginLayoutComponent],
    imports: [
        CommonModule,
        LoginRoutingModule,
        MatFormFieldModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        ReactiveFormsModule
    ]
})
export class LoginModule {
}
