<form (ngSubmit)="this.onSaveClick()" [formGroup]="formGroup">
    <div class="card">
        <div class="card-header" i18n>
            Crear usuario
        </div>

        <div class="card-body">
            <div class="row">
                <ng-container *ngFor="let property of properties" [ngSwitch]="true">
                    <ng-container *ngSwitchCase="property.selectable">
                        <div [ngClass]="['form-group', property.wrapperClass]">
                            <label [for]="'form-' + property.label">{{property.label}}</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-select [formControlName]="property.name" [id]="'form-' + property.label"
                                            [placeholder]="property.placeholder" [required]="property.required">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let option of this[property.dataSource]"
                                                [value]="option.id">
                                        {{ option.name }}
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="!formGroup.controls[property.name].valid" i18n>Por favor, selecciona un
                                    valor.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="property.type === 'boolean'">
                        <div [ngClass]="['form-group', property.wrapperClass]">
                            <mat-checkbox [formControlName]="property.name" [id]="property.name">{{property.label}}</mat-checkbox>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <div [ngClass]="['form-group', property.wrapperClass]">
                            <label [for]="property.name">{{property.label}}</label>
                            <input [formControlName]="property.name" [id]="property.name" [name]="property.name"
                                   [placeholder]="property.placeholder"
                                   [required]="property.required" [type]="property.type"
                                   class="form-control"
                                   matInput/>

                            <mat-error
                                *ngIf="!formGroup.controls[property.name].valid && formGroup.controls[property.name].touched" i18n>
                                Este campo no tiene un valor válido.
                            </mat-error>
                        </div>
                    </ng-container>
                </ng-container>

            </div>

            <div *ngIf="loading" class="d-flex justify-content-center align-items-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>

        <div class="card-footer">
            <button [disabled]="loading || !formGroup.valid" [title]="!formGroup.valid ? 'Por favor, rellena todos los datos.' : 'Guardar'"
                    class="btn btn-sm btn-primary mr-2" type="submit">
                <i class="icon-check icons"></i>&nbsp;<ng-container i18n>Guardar</ng-container>
            </button>
            <button [disabled]="loading" class="btn btn-sm btn-secondary" routerLink="/user" type="button">
                <i class="icon-close icons"></i>&nbsp;<ng-container i18n>Cancelar</ng-container>
            </button>
        </div>
    </div>
</form>
