<div class="card card-main" >

    <div class="row mr-0 ml-0" *ngIf="!loading">
        <ng-container>
            <div [ngClass]="{'embed-responsive streaming-player embed-responsive-16by9' : true, 'col-md-12' : !settings.showChat, 'col-md-8' : settings.showChat}">
                <iframe [src]="safeSrcStreaming" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
            </div>
        </ng-container>
        <div *ngIf="settings.showChat" class="col-md-4 card-body card-body-chat">
            <iframe [src]="safeSrcChat" width="100%" height="100%" frameborder="0"></iframe>
        </div>
    </div>

    <div class="card-footer col-md-12" *ngIf="!loading">
        <ng-container>
            <div class="col-md-8 streaming-description ">
                <h1 class="text-page-title-color">{{settings.title}}</h1>
                <div [innerHTML]="settings.description"></div>
            </div>
        </ng-container>
    </div>
</div>

