import {Component, Input, OnInit} from '@angular/core';
import {UserHttpModel} from '../../../models/http/UserHttpModel';
import {UserHttpService} from '../../../services/http/user-http.service';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDataSources} from '../../../models/user-datasources';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

interface FormProperty {
    name: string;
    type: string;
    label: string;
    placeholder: string;
    defaultValue: string;
    editable: boolean;
    value: any;
    required: boolean;
    minValue?: number;
    patternValidation?: string;
    selectable?: boolean;
    dataSource?: string;
    autocomplete?: boolean;
    autocompleteOptions?: string;
    autocompleteInitValue?: string;
    wrapperClass?: string;
    valueChanges?: () => void;
}

interface DataSources {
    roles: Observable<any>;
    users: Observable<UserHttpModel[]>;
}

@Component({
    selector: 'app-detail-form-util',
    templateUrl: './detail-form-util.component.html',
    styleUrls: ['./detail-form-util.component.scss']
})
export class DetailFormUtilComponent implements OnInit {

    @Input() id: any;
    profilePicture: File = null;
    editable: boolean;

    private rolHttpModels: any;

    constructor(
        private userHttpService: UserHttpService,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private dialog: MatDialog
    ) {
    }

    private _properties: FormProperty[] = [
        {
            name: 'name',
            type: 'string',
            label: $localize`Nombre`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-6'
        },
        {
            name: 'lastName',
            type: 'string',
            label: $localize`Apellidos`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-6'
        },
        {
            name: 'email',
            type: 'email',
            label: $localize`Email`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-6'
        },
        {
            name: 'dni',
            type: 'string',
            label: $localize`DNI`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-6',
            patternValidation: '^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$',
            valueChanges: () => {
                if (!this.model.dni) {
                    return;
                }

                this.model.dni = this.model.dni.toUpperCase();
                this.formGroup.get('dni').setValue(this.model.dni, {emitEvent: false});
            }
        },
        {
            name: 'phone',
            type: 'string',
            label: $localize`Número de teléfono`,
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-6',
            patternValidation: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{0,8}$',
        },
        {
            name: 'isAdmin',
            type: 'boolean',
            label: 'Administrador',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-4'
        },

    ];

    get properties(): FormProperty[] {
        return this._properties;
    }

    set properties(value: FormProperty[]) {
        this._properties = value;
    }

    private _model = new UserHttpModel();

    get model(): UserHttpModel {
        return this._model;
    }

    set model(value: UserHttpModel) {
        this._model = value;
    }

    private _loading = false;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    private _recoveryClicked = false;

    get recoveryClicked(): boolean {
        return this._recoveryClicked;
    }

    set recoveryClicked(value: boolean) {
        this._recoveryClicked = value;
    }

    private _formGroup: FormGroup;

    get formGroup(): FormGroup {
        return this._formGroup;
    }

    set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    currentUser: UserHttpModel;

    ngOnInit() {
        this.loadModel();
        this.initFormGroup();

        this.currentUser = this.authenticationService.session.getValue().user;

        /* if the url has the last segment 'edit' set editing to true */
        this.route.url.subscribe(segments => {
            this.editable = segments.pop().path === 'edit';
            this.updatePropertiesState();
        });
    }

    /**
     * Load model information
     */
    loadModel() {
        this.loading = true;
        this.userHttpService.getDetail(this.id).subscribe(
            (ok) => {
                this.loading = false;
                this.model = ok;
                for (const property of this.properties) {
                    this.formGroup.controls[property.name].setValue(this.model[property.name]);
                }
            },
            (ko) => {
                this.loading = false;
                this.snackBar.open($localize`Ha ocurrido un error cargando el usuario.`, $localize`Aceptar`, {duration: 5000});
                throw ko;
            }
        );
    }

    /**
     * Init form group
     */
    initFormGroup() {
        const formControls = {};
        for (const property of this.properties) {
            formControls[property.name] = [{
                value: property.defaultValue,
                disabled: !property.editable
            }, []];

            // Add validator if required
            if (property.required) {
                formControls[property.name][1].push(Validators.required);
            }

            // Add email validator
            if (property.type === 'email') {
                formControls[property.name][1].push(Validators.email);
            }

            // Add number validator
            if (property.type === 'number') {
                formControls[property.name][1].push(Validators.min(property.minValue));
            }

            // Add pattern validator if string
            if (property.type === 'string' && property.patternValidation) {
                formControls[property.name][1].push(Validators.pattern(property.patternValidation));
            }
        }

        this.formGroup = this.formBuilder.group(formControls);

        for (const property of this.properties) {
            this.formGroup.get(property.name).valueChanges.subscribe(value => {
                this.model[property.name] = value;

                if (property.valueChanges) {
                    property.valueChanges();
                }
            });
        }
    }

    /**
     * Form events
     */
    onSaveClick() {
        this.saveModel();
    }

    saveModel() {
        this.loading = true;
        this.userHttpService.edit(this.model).subscribe(
            (ok) => {
                this.loading = false;
                this.snackBar.open($localize`El usuario se ha guardado correctamente.`, $localize`Aceptar`, {duration: 5000});
                this.router.navigate([`/user`]);
            },
            (ko) => {
                this.snackBar.open($localize`Ha ocurrido un error guardando el usuario.`, $localize`Aceptar`, {duration: 5000});
                this.loading = false;
            }
        );
    }

    onRecoveryClick() {
        this.authenticationService.passwordRecovery(this.model.email)
            .subscribe(
                () => this.snackBar.open(
                    $localize`Se le ha enviado un correo para la recuperación de contraseña a ${this.model.email}`,
                    $localize`Aceptar`,
                    {duration: 5000}
                ),
                (error) => {
                    this.snackBar.open(
                        $localize`No se ha podido enviar un correo para la recuperación de contraseña a ${this.model.email}`,
                        $localize`Aceptar`,
                        {duration: 5000}
                    );
                    this._recoveryClicked = false;
                    throw error;
                },
                () => this._recoveryClicked = true
            );
    }

    onBack() {
        this.location.back();
    }

    setEditing() {
        this.router.navigate([`/user/${this.id}/edit`]);
    }

    private updatePropertiesState() {
        this.editable ? this.formGroup.enable() : this.formGroup.disable();
        this.properties.forEach(property => {
            if (!property.editable) {
                this.formGroup.get(property.name).disable();
                return;
            }
            property.editable = this.editable;
        });
    }

    onDelete() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: $localize`¿Quieres eliminar este usuario?`});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.userHttpService.remove(
                    this.model
                ).subscribe(
                    (ok) => {
                        this.router.navigate(['user']);
                    },
                    (ko) => {
                        this.snackBar.open($localize`Ocurrió un error intentando eliminar el usuario.`, $localize`Aceptar`, {duration: 5000});
                        throw ko;
                    }
                );
            }
        });
    }
}
