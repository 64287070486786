<user-filters
    (filterChange)="loadUserListInformation($event)"
    [queryParameters]="queryParameters"
></user-filters>

<div class="card">
    <div class="card-header" i18n>
        Usuarios
    </div>

    <div class="card-body">
        <div *ngIf="!loading && userDataSource.length == 0">
            <p i18n>No existen datos todavía.</p>
        </div>

        <table [dataSource]="userDataSource" [hidden]="loading || !userDataSource.length" class="mat-table-responsive"
               mat-table>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th *matHeaderCellDef class="w-20" i18n mat-header-cell>Nombre</th>
                <td *matCellDef="let element" data-name="Nombre" mat-cell>{{element.name}}</td>
            </ng-container>

            <!-- Lastname Column -->
            <ng-container matColumnDef="lastName">
                <th *matHeaderCellDef class="w-20" i18n mat-header-cell>Apellidos</th>
                <td *matCellDef="let element" data-name="Apellidos" mat-cell>{{element.lastName}}</td>
            </ng-container>

            <!-- Lastname Column -->
            <ng-container matColumnDef="isAdmin">
                <th *matHeaderCellDef class="w-20" i18n mat-header-cell>Administrador</th>
                <td *matCellDef="let element" data-name="Apellidos" mat-cell><mat-checkbox [(ngModel)]="element.isAdmin" [disabled]="true"></mat-checkbox></td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th *matHeaderCellDef class="w-10" i18n mat-header-cell>Acciones</th>
                <td *matCellDef="let element" class="actions" mat-cell>
                    <div class="btn-group" dropdown >
                        <button aria-controls="dropdown-basic" class="btn btn-sm btn-primary dropdown-toggle"
                                dropdownToggle id="button-basic" type="button">
                            <ng-container i18n>Acciones</ng-container>&nbsp;<span class="caret"></span>
                        </button>
                        <ul *dropdownMenu aria-labelledby="button-basic" class="dropdown-menu dropdown-menu-right show"
                            id="dropdown-basic" role="menu">
                            <li>
                                <a class="dropdown-item" routerLink="/user/{{ element.id }}">
                                    <i class="icon-eye icons"></i>&nbsp;
                                    <ng-container i18n>Ver</ng-container>
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" routerLink="/user/{{ element.id }}/edit">
                                    <i class="icon-pencil icons"></i>&nbsp;
                                    <ng-container i18n>Editar</ng-container>
                                </a>
                            </li>
                            <li>
                                <span (click)="onRecoveryClick(element)" class="dropdown-item c-pointer">
                                    <i class="icon-lock-open icons"></i>&nbsp;
                                    <ng-container i18n>Restaurar contraseña</ng-container>
                                </span>
                            </li>
                            <ng-container>
                                <li class="divider dropdown-divider"></li>
                                <li role="menuitem">
                                    <span (click)="remove(element)" class="dropdown-item c-pointer">
                                        <i class="icon-trash icons"></i>&nbsp;<ng-container i18n>Eliminar</ng-container>
                                    </span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>

        <mat-spinner *ngIf="loading"></mat-spinner>

        <mat-paginator (page)="onEventPagination($event)" [hidden]="loading || !userDataSource.length"
                       [length]="length" [pageIndex]="pageIndex" [pageSizeOptions]="[pageSize]" [pageSize]="pageSize"
                       showFirstLastButtons></mat-paginator>
    </div>

    <div class="card-footer">
        <div>
            <button class="btn btn-sm btn-primary mr-2" routerLink="/user/create" type="submit">
                <i class="icon-plus icons"></i>&nbsp;<ng-container i18n>Crear usuario</ng-container>
            </button>
        </div>
    </div>
</div>
