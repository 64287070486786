import {UserHttpModel} from "../models/http/UserHttpModel";
import {UserListHttpModel} from "../models/http/UserListHttpModel";

/**
 * Mapper to cast raw information to user models
 */
export class UserHttpMapper {

    /**
    * Model raw information to UserHttpModel
    *
    * @param response
    */
    static map(response): UserHttpModel {
        const model = new UserHttpModel();

        /**
         * Get keys and values
         */
        let keys = Object.keys(response);
        let values = Object.values(response);

        /**
         * set properties
         */
        for (let i = 0; i < keys.length; i++) {
          model['_' + keys[i]] = values[i];
        }

        return model;
    }

    /**
     * Model raw information to UserListHttpModel
     *
     * @param response
     */
    static mapList(response): UserListHttpModel {
        /**
         * Content output
         */
        const out = [];

        /**
         * Populate content output
         */
        for (let userRequest of response.content) {
            out.push(
                this.map(userRequest)
            );
        }

        /**
         * List of elements
         */
        const list = new UserListHttpModel();

        /**
         * Populate pagination settings
         */
        list.pageSize = response.pageSize;
        list.pageNumber = response.pageNumber;
        list.totalPages = response.totalPages;
        list.totalElements = response.totalElements;
        list.size = response.size;
        list.numberOfElements = response.numberOfElements;

        /**
         * Populate List with output
         */
        list.userList = out;

        return list;
    }
}
