<form>
    <h1 i18n>Contraseña</h1>
    <p class="text-muted" i18n>Recupera tu contraseña.</p>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="icon-envelope"></i></span>
      </div>
      <input class="form-control" type="email" name="email" [(ngModel)]="email" placeholder="Email" required>
    </div>

    <div class="input-group mb-4" *ngIf="error" i18n>Email incorrecto</div>

    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <div class="row" *ngIf="!loading">
      <div class="col-12">
        <button type="submit" class="w-100 btn btn-primary px-4" (click)="onSubmit()" i18n>Recuperar contraseña</button>
      </div>
    </div>
</form>
