import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-user-detail-page',
    templateUrl: './user-detail-page.component.html',
    styleUrls: ['./user-detail-page.component.scss']
})
export class UserDetailPageComponent implements OnInit {

    /**
     * Constructor
     *
     * @param route to get the url id
     */
    constructor(private route: ActivatedRoute) {
        /**
         * Get id from url to get the detail
         */
        this.route.params.subscribe(
            (params) => {
                this.id = params.id;
            }
        );
    }

    private _id;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    ngOnInit() {
    }
}
