import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-recovery-page',
    templateUrl: './recovery-page.component.html',
    styleUrls: ['./recovery-page.component.scss']
})
export class RecoveryPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
