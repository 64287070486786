<div class="card">
    <div class="card-header d-flex">
        <strong i18n>Usuario</strong>:&nbsp;{{this.model.name}} {{this.model.lastName}}
    </div>

    <div class="card-body">
        <div *ngIf="!this.loading" class="container">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="formGroup">
                        <div class="row">
                            <ng-container *ngFor="let property of properties" [ngSwitch]="true">
                                <ng-container *ngSwitchCase="property.selectable">
                                    <div [ngClass]="['form-group', property.wrapperClass]">
                                        <label [for]="property.name">{{property.label}}</label>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-select [formControlName]="property.name"
                                                        [id]="property.name" [placeholder]="property.placeholder"
                                                        [required]="property.required">
                                                <mat-option>--</mat-option>
                                                <mat-option
                                                        *ngFor="let option of this[property.dataSource]"
                                                        [value]="option.id">
                                                    {{ option.name }}
                                                </mat-option>
                                            </mat-select>

                                            <mat-error *ngIf="!formGroup.controls[property.name].valid" i18n>
                                                Por favor, selecciona un valor.
                                            </mat-error>

                                        </mat-form-field>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase="property.type === 'boolean'">
                                    <div [ngClass]="['form-group', property.wrapperClass]">
                                        <mat-checkbox [formControlName]="property.name" [id]="property.name">{{property.label}}</mat-checkbox>
                                    </div>
                                </ng-container>
            

                                <ng-container *ngSwitchDefault>
                                    <div [ngClass]="['form-group', property.wrapperClass]">
                                        <label [for]="property.name">{{property.label}}</label>
                                        <input [formControlName]="property.name"
                                               [id]="property.name" [name]="property.name"
                                               [placeholder]="property.placeholder"
                                               [required]="property.required" [type]="property.type"
                                               class="form-control"/>

                                        <mat-error
                                                *ngIf="!formGroup.controls[property.name].valid && formGroup.controls[property.name].touched"
                                                i18n>
                                            Este campo no tiene un valor válido.
                                        </mat-error>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div *ngIf="loading" class="d-flex justify-content-center align-items-center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="card-footer d-flex flex-wrap">
        <ng-container *ngIf="!loading">
            <ng-container *ngIf="editable">
                <ng-container *ngIf="currentUser.id !== model.id">
                    <ng-container>
                        <button (click)="onSaveClick()" [disabled]="!formGroup.valid"
                                [title]="!formGroup.valid ? 'Por favor, rellena todos los datos.' : 'Guardar'"
                                class="btn btn-sm btn-primary mr-2"
                                type="submit">
                            <i class="icon-check icons"></i>&nbsp;<ng-container i18n>Guardar usuario</ng-container>
                        </button>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="currentUser.id === model.id">
                    <button (click)="onSaveClick()" [disabled]="!formGroup.valid"
                            [title]="!formGroup.valid ? 'Por favor, rellena todos los datos.' : 'Guardar'"
                            class="btn btn-sm btn-primary mr-2"
                            type="submit">
                        <i class="icon-check icons"></i>&nbsp;<ng-container i18n>Guardar usuario</ng-container>
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!editable">
                <ng-container *ngIf="currentUser.id !== model.id">
                    <ng-container>
                        <button (click)="setEditing()" class="btn btn-sm btn-primary mr-2" type="submit">
                            <i class="icon-check icons"></i>
                            &nbsp;<ng-container i18n>Editar usuario</ng-container>
                        </button>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="currentUser.id === model.id">
                    <button (click)="setEditing()" class="btn btn-sm btn-primary mr-2" type="submit">
                        <i class="icon-check icons"></i>
                        &nbsp;<ng-container i18n>Editar usuario</ng-container>
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
        <button (click)="onBack()" class="btn btn-sm btn-secondary mr-2" type="button">
            <i class="icon-close icons"></i>&nbsp;<ng-container i18n>Cancelar</ng-container>
        </button>

        <ng-container *ngIf="currentUser.id !== model.id">
            <ng-container>
                <button (click)="onRecoveryClick()" [disabled]="recoveryClicked"
                        class="btn btn-sm btn-dark align-self-start ml-auto"
                        type="button">
                    <i class="icon-lock-open"></i>&nbsp;<ng-container i18n>Recuperar contraseña</ng-container>
                </button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="currentUser.id === model.id">
            <button (click)="onRecoveryClick()" [disabled]="recoveryClicked"
                    class="btn btn-sm btn-dark align-self-start ml-auto"
                    type="button">
                <i class="icon-lock-open"></i>&nbsp;<ng-container i18n>Recuperar contraseña</ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="editable && currentUser.id !== model.id">
            <ng-container>
                <button (click)="onDelete()" class="btn btn-sm btn-dark  align-self-start ml-2" type="button"><i
                        class="icon-trash icons"></i>
                    &nbsp;<ng-container i18n>Borrar usuario</ng-container>
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
