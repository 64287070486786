import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {map} from "rxjs/operators";
import {Observable} from "rxjs"
import { StreamingSettingsHttpModel } from '../models/StreamingSettingsHttpModel';
import { StreamingSettingsHttpMapper } from '../mappers/StreamingSettingsHttpMapper';

@Injectable({
    providedIn: 'root'
})
export class StreamingSettingsHttpService {

    /**
     * Added HttpClient to make http requests
     *
     * @param httpClient
     */
    constructor(
        private httpClient: HttpClient
    ) {
    }

    /**
     * Entity url for REST
     */
    private _entityUrl = 'streaming/settings';

    // region REST methods

    get entityUrl(): string {
        return this._entityUrl;
    }

    set entityUrl(value: string) {
        this._entityUrl = value;
    }

    /**
     * Return Observable<SessionHttpModel>
     *
     * @param id string|number
     * @return Observable<SessionHttpModel>
     */
    get(): Observable<StreamingSettingsHttpModel> {

        /**
         * Make request to backend from Session and return data mapper on SessionHttpModel
         */
        return this.httpClient.get(environment.apiUrl + '/' + this.entityUrl).pipe(
            map(
                response => {
                    return StreamingSettingsHttpMapper.map(response);
                }
            )
        );
    }

    /**
     *
     * @param streamingSettingsHttpModel
     * @return Observable<SessionHttpModel>
     */
    edit(streamingSettingsHttpModel: StreamingSettingsHttpModel): Observable<StreamingSettingsHttpModel> {
        /**
         * Parse model to body json
         */
        const keys = Object.keys(streamingSettingsHttpModel);
        const values = Object.values(streamingSettingsHttpModel);
        const body = {};

        for (let i = 0; i < keys.length; i++) {
            body[keys[i].substr(1)] = values[i];
        }

        // Here can be added custom modifications over the http body

        return this.httpClient.put<StreamingSettingsHttpModel>(
            environment.apiUrl + '/' + this.entityUrl,
            body
        ).pipe(
            map(response => {
                return StreamingSettingsHttpMapper.map(response);
            })
        );
    }
}
