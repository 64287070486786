import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserHttpService} from '../../../services/http/user-http.service';
import {UserHttpModel} from '../../../models/http/UserHttpModel';

@Component({
    selector: 'app-detail-card-util',
    templateUrl: './detail-card-util.component.html',
    styleUrls: ['./detail-card-util.component.scss']
})
export class DetailCardUtilComponent implements OnInit {
    @Output() isEditable = new EventEmitter<boolean>();
    @Input() private id: any;

    constructor(
        private userHttpService: UserHttpService) {
    }

    private _loading: boolean;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    private _error: boolean;

    get error(): boolean {
        return this._error;
    }

    set error(value: boolean) {
        this._error = value;
    }

    private _model = new UserHttpModel();

    get model(): UserHttpModel {
        return this._model;
    }

    set model(value: UserHttpModel) {
        this._model = value;
    }

    ngOnInit() {
        this.loadModel();
    }

    /**
     * Load model information
     */
    loadModel() {
        this.loading = true;
        this.userHttpService.getDetail(this.id).subscribe(
            (ok) => {
                this.loading = false;
                this.model = ok;
            },
            (ko) => {
                this.loading = false;
                this.error = true;
                throw ko;
            }
        );
    }

}
