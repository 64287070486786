export class StreamingSettingsHttpModel {

    constructor() {
    }

    private _id: number;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    private _streamingCode: string;

    get streamingCode(): string {
        return this._streamingCode;
    }

    set streamingCode(value: string) {
        this._streamingCode = value;
    }

    private _chatCode: string;

    get chatCode(): string {
        return this._chatCode;
    }

    set chatCode(value: string) {
        this._chatCode = value;
    }

    private _title: string;

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    private _description: string;

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    private _showChat: boolean;

    get showChat(): boolean {
        return this._showChat;
    }

    set showChat(value: boolean) {
        this._showChat = value;
    }

}
