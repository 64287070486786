import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
    sidebarMinimized = false;
    cYear: number = new Date().getFullYear();
    logged: Observable<boolean>;

    constructor(private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.logged = this.authService.session.pipe(map(session => !!session));
    }

    toggleMinimize(e) {
        this.sidebarMinimized = e;
    }

    onClick() {
        
    }

}
