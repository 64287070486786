import {UserHttpService} from '../../../services/http/user-http.service';
import {UserHttpModel} from '../../../models/http/UserHttpModel';
import {UserListHttpModel} from '../../../models/http/UserListHttpModel';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {UserQueryParameters} from '../../../models/user-query-parameters';
import {UserDataSources} from '../../../models/user-datasources';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {UserDataService} from '../../../services/user-data.service';

@Component({
    selector: 'app-user-table-list-util',
    templateUrl: './user-table-list-util.component.html',
    styleUrls: ['./user-table-list-util.component.scss']
})
export class UserTableListUtilComponent implements OnInit {
    // Table configuration
    userDataSource = [];
    displayedColumns: string[] = ['name', 'lastName', 'isAdmin', 'action'];
    indexedCompanies = {};
    indexedWorkCenters = {};
    indexedWorkTeams = {};

    // Pagination configuration
    pageIndex = 0;
    pageSize = 10;
    length: number;

    loading = false;

    // Query parameters
    queryParameters: UserQueryParameters = {
        page: this.pageIndex,
        size: this.pageSize,
        name: '',
        lastName: '',
    };

    userListModel: UserListHttpModel;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Data sources related to the user
    dataSources: UserDataSources = {};

    constructor(
        private userHttpService: UserHttpService,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private userDataService: UserDataService
    ) {
    }

    private _recoveryClicked = false;

    get recoveryClicked(): boolean {
        return this._recoveryClicked;
    }

    set recoveryClicked(value: boolean) {
        this._recoveryClicked = value;
    }

    ngOnInit() {
        if (this.userDataService.user) {
            this.queryParameters = this.userDataService.user;
        }

        this.loadUserListInformation(this.queryParameters);
    }

    /**
     * Get user list
     */
    loadUserListInformation(queryParameters: UserQueryParameters) {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.userDataSource = [];
        this.userHttpService.getList(queryParameters).subscribe(
            (response) => {
                if (response.userList.length > 0) {
                    this.userDataSource = response.userList;
                }
                this.userListModel = response;

                // update pagination
                this.length = response.totalElements;
                this.pageIndex = response.pageNumber;

                this.loading = false;
            },
            (error) => {
                this.loading = false;
                this.snackBar.open($localize`Ocurrió un error intentando cargar los usuarios.`, $localize`Aceptar`, {duration: 5000});
                throw error;
            }
        );
    }

    remove(user: UserHttpModel) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: $localize`¿Quieres eliminar este usuario?`});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.userHttpService.remove(
                    user
                ).subscribe(
                    (ok) => {
                        this.loadUserListInformation(this.queryParameters);
                    },
                    (ko) => {
                        this.snackBar.open(
                            $localize`Ocurrió un error intentando eliminar el usuario.`,
                            $localize`Aceptar`,
                            {duration: 5000}
                        );
                        this.loadUserListInformation(this.queryParameters);
                        throw ko;
                    }
                );
            }
        });
    }

    onEventPagination(event: any) {
        this.queryParameters.page = event.pageIndex;
        this.loadUserListInformation(this.queryParameters);
    }

    /**
     * This method evaluates if everything was loaded, so components can be printed
     */
    isLoaded() {
        return !this.loading;
    }

    onRecoveryClick(user: UserHttpModel) {
        this.authenticationService.passwordRecovery(user.email)
            .subscribe(
                () => this.snackBar.open(
                    $localize`Se le ha enviado un correo para la recuperación de contraseña a ${user.email}`,
                    $localize`Aceptar`,
                    {duration: 5000}
                ),
                (error) => {
                    this._recoveryClicked = false;
                    throw error;
                },
                () => this._recoveryClicked = true
            );
    }
}
