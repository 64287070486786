/* Import module definition */
import {NgModule} from '@angular/core';
/* Import routes modules */
import {RouterModule, Routes} from '@angular/router';
/* Import List page component */
import {UserPageComponent} from './components/pages/list-page/user-list-page.component';
import {UserCreatePageComponent} from './components/pages/create-page/user-create-page.component';
import {UserEditPageComponent} from './components/pages/edit-page/user-edit-page.component';

/**
 * Route management definition
 *
 * [
 * {path: '', component: ObjectComponent},
 * {path: 'detail', component: ObjectComponent, children: {path: ':id', component: ObjectChildComponent}}
 * {path: '**', component: NotFoundErrorComponent},
 * ]
 */
const routes: Routes = [
    {
        path: '',
        component: UserPageComponent
    },
    {
        path: 'create',
        component: UserCreatePageComponent
    },
    {
        path: ':id',
        component: UserEditPageComponent
    },
    {
        path: ':id/edit',
        component: UserEditPageComponent
    },
];

/**
 * Module definition
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

/**
 * Routing component declaration for User
 */
export class UserRoutingModule {
}
