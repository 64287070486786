import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {LanguageSelectorDialogComponent} from '../../../dialogs/language-selector-dialog/language-selector-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-user-nav-util',
    templateUrl: './nav-util.component.html',
    styleUrls: ['./nav-util.component.scss']
})
export class NavUtilComponent implements OnInit, OnDestroy {
    user: any;
    environment = environment;
    imageUrl = 'assets/img/avatar.png';
    urlSubscription: Subscription;
    loading = true;
    isAdmin = false;

    constructor(private authService: AuthenticationService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.authService.session.getValue().user.isAdmin && (this.isAdmin = true);
    }

    ngOnDestroy(): void {
        this.urlSubscription.unsubscribe();
    }

    onLoad() {
        this.loading = false;
    }

    logout() {
        this.authService.logout();
    }

    onLanguageDialog() {
        this.dialog.open(LanguageSelectorDialogComponent, {
            width: '17vw'
        });
    }
}
