import {Component, OnInit, VERSION ,ViewChild} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserHttpModel } from '../../../models/http/UserHttpModel';
import {UserHttpService} from "../../../services/http/user-http.service";

@Component({
    selector: 'app-user-bulk-create-page',
    templateUrl: './user-bulk-create-page.component.html',
    styleUrls: ['./user-bulk-create-page.component.scss']
})
export class UserBulkCreatePageComponent {

    public records: UserHttpModel[] = [];
    @ViewChild('csvReader') csvReader: any;
    jsondatadisplay:any;
  
    constructor(
      private userHttpService: UserHttpService,
      private route: Router,
      private snackBar: MatSnackBar
  ) {
  }

    uploadListener($event: any): void {
      let text = [];
      let files = $event.srcElement.files;
  
      if (this.isValidCSVFile(files[0])) {
  
        let input = $event.target;
        let reader = new FileReader();
        reader.readAsText(input.files[0]);
  
        reader.onload = () => {
          let csvData = reader.result as string;
          let csvRecordsArray = (csvData).split(/\r\n|\n/);
          let headersRow = this.getHeaderArray(csvRecordsArray);
          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        };
  
        reader.onerror = function () {
          console.log('error is occured while reading file!');
        };
  
      } else {
        alert("Please import valid .csv file.");
        this.fileReset();
      }
    }
  
    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
      let csvArr = [];
  
      for (let i = 1; i < csvRecordsArray.length; i++) {
        let curruntRecord = (csvRecordsArray[i]).split(';');
        if (curruntRecord.length == headerLength) {
          let csvRecord: UserHttpModel = new UserHttpModel();
          csvRecord.name = curruntRecord[0].trim();
          csvRecord.lastName = curruntRecord[1].trim();
          csvRecord.email = curruntRecord[2].trim();
          csvRecord.dni = curruntRecord[3].trim();
          csvRecord.phone = curruntRecord[4].trim();
          csvArr.push(csvRecord);
        }
      }
      return csvArr;
    }
  
  //check etension
    isValidCSVFile(file: any) {
      return file.name.endsWith(".csv");
    }
  
    getHeaderArray(csvRecordsArr: any) {
      let headers = (csvRecordsArr[0]).split(';');
      let headerArray = [];
      for (let j = 0; j < headers.length; j++) {
        headerArray.push(headers[j]);
      }
      return headerArray;
    }
  
    fileReset() {
      this.csvReader.nativeElement.value = "";
      this.records = [];
      this.jsondatadisplay = '';
    }
  
    getJsonData(){
      this.jsondatadisplay = JSON.stringify(this.records);
    }

    onSave() {
      if(this.records && this.records.length > 0) {
        this.userHttpService.bulkUpdate(this.records).subscribe(
          (ok) => {
              this.snackBar.open($localize`Los usuarios se han guardado.`, $localize`Aceptar`, {duration: 5000});
              location.reload();
          },
          (ko) => {
              this.snackBar.open($localize`Ha ocurrido un error`, $localize`Aceptar`, {duration: 5000});
              throw ko;
          }
        );
      }
    }
}
