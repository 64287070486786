<div class="modal-custom">
    <div mat-dialog-title class="text-center">
    </div>
    <div mat-dialog-content class="text-center">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1"
            [resizeToWidth]="500"
            [roundCropper]="true"
            (imageCropped)="onImageCropped($event)"
            (imageLoaded)="setSize($event)"
        ></image-cropper>
    </div>
    <div class="d-flex justify-content-center flex-wrap mt-2">
        <button style="width: 150px" (click)="onNoClick()" class="mx-2 btn btn-secondary"><span i18n>Cerrar</span></button>
            <button style="width: 150px" (click)="onAccept()" class="mx-2 btn btn-primary"><span i18n>Aceptar</span></button>
    </div>
</div>
