import { StreamingSettingsHttpModel } from "../models/StreamingSettingsHttpModel";

/**
 * Mapper to cast raw information to user models
 */
export class StreamingSettingsHttpMapper {

    /**
    * Model raw information to UserHttpModel
    *
    * @param response
    */
    static map(response): StreamingSettingsHttpModel {
        const model = new StreamingSettingsHttpModel();

        /**
         * Get keys and values
         */
        let keys = Object.keys(response);
        let values = Object.values(response);

        /**
         * set properties
         */
        for (let i = 0; i < keys.length; i++) {
          model['_' + keys[i]] = values[i];
        }

        return model;
    }
}
