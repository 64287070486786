<div class="animated fadeIn">
    <div class="card">
        <div class="card-header d-flex">
            <strong i18n>Configuración Streaming</strong>
        </div>

        <div class="card-body">
            <div *ngIf="!loading" class="container">
                <div class="row">
                    <div class="col-12">
                        <form [formGroup]="formGroup">
                            <div class="row">
                                <ng-container *ngFor="let property of properties" [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="property.type === 'boolean'">
                                        <div [ngClass]="['form-group', property.wrapperClass]">
                                            <mat-checkbox [formControlName]="property.name" [id]="property.name">{{property.label}}</mat-checkbox>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <div [ngClass]="['form-group', property.wrapperClass]">
                                            <label [for]="property.name">{{property.label}}</label>
                                            <input [formControlName]="property.name"
                                                [id]="property.name" [name]="property.name"
                                                [placeholder]="property.placeholder"
                                                [required]="property.required" [type]="property.type"
                                                class="form-control"/>

                                            <mat-error
                                                    *ngIf="!formGroup.controls[property.name].valid && formGroup.controls[property.name].touched"
                                                    i18n>
                                                Este campo no tiene un valor válido.
                                            </mat-error>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </form>
                        <ng-container>
                            <div class="form-group col-md-12">
                                <label >Descripcion</label>
                                <ckeditor [editor]="Editor" [data]="model.description" [disabled]="!editable" (change)="onChangeEditor($event)"></ckeditor>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div *ngIf="loading" class="d-flex justify-content-center align-items-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>

        <div class="card-footer">
            <ng-container *ngIf="!editable">
                <button class="btn btn-sm btn-primary mr-2" (click)="setEditing()" type="button"><i
                    class="icon-pencil icons"></i>
                    Editar
                </button>
            </ng-container>
            <ng-container *ngIf="editable">
                <button class="btn btn-sm btn-primary mr-2" (click)="saveModel()" type="button"><i
                    class="icon-check icons"></i>
                    Guardar
                </button>
            </ng-container>
            <button class="btn btn-sm btn-secondary mr-2" (click)="onBack()" type="button"><i
                class="icon-action-undo icons"></i>
                Volver
            </button>
        </div>
    </div>
</div>
