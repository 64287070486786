<div class="nav-item" dropdown placement="bottom right">
    <a (click)="false" aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" dropdownToggle
       href="#" role="button">
        <!-- <img (load)="onLoad()" [hidden]="loading" [src]="imageUrl | authImage | async" alt="User" class="img-avatar"/>
        <img [hidden]="!loading" src="../../../../../../assets/img/avatar.png" alt="User" class="img-avatar"/> -->
    </a>
    <div>
        <!-- <div class="dropdown-header text-center">
            <strong>
                <ng-container i18n>Hola</ng-container>
                {{ user.name | titlecase }}</strong>
        </div>
        <a class="dropdown-item" routerLink="/user/{{ user.id }}/edit">
            <i class="fa fa-user"></i>
            <ng-container i18n>Perfil</ng-container>
        </a>
        <a (click)="onLanguageDialog()" class="dropdown-item c-pointer">
            <i class="fa fa-globe"></i>
            <ng-container i18n>Cambiar idioma</ng-container>
        </a> -->
        <a [routerLink]="['/streaming/settings']" class="dropdown-item button-secondary-custtom c-pointer" *ngIf="isAdmin" style="display:inline; margin: 10px">
            <i class="fa fa-cog"></i>
            <ng-container i18n>Configuración</ng-container>
        </a>
        <a [routerLink]="['/user']" class="dropdown-item button-secondary-custtom c-pointer" *ngIf="isAdmin" style="display:inline; margin: 10px">
            <i class="fa fa-users"></i>
            <ng-container i18n>Usuarios</ng-container>
        </a>
        <a (click)="logout()" class="dropdown-item button-secondary-custtom c-pointer" style="display:inline; margin: 10px">
            <i class="fa fa-sign-out"></i>
            <ng-container i18n>Salir</ng-container>
        </a>
    </div>
</div>
