import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';

@Component({
    selector: 'app-profile-picture-cropper-dialog',
    templateUrl: './profile-picture-cropper-dialog.component.html',
    styleUrls: ['./profile-picture-cropper-dialog.component.scss']
})
export class ProfilePictureCropperDialogComponent implements OnInit {

    imageChangedEvent: Event;
    image: File;

    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    constructor(public dialogRef: MatDialogRef<ProfilePictureCropperDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    imageChangedEvent: Event
                    imageName: string,
                    type: string
                }) {
    }

    ngOnInit(): void {
        this.imageChangedEvent = this.data.imageChangedEvent;
    }

    onNoClick() {
        this.dialogRef.close();
    }

    onAccept() {
        this.dialogRef.close(this.image);
    }

    onImageCropped(event: ImageCroppedEvent) {
        const imageUrl = event.base64;
        const imageBlob = this.dataURItoBlob(imageUrl);
        this.image = new File([imageBlob], this.data.imageName, {type: this.data.type});
    }

    dataURItoBlob(dataURI) {
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob(
            [new Uint8Array(array)],
            {
                type: this.data.type,
            },
        );
    }

    setSize($event: void) {
        this.imageCropper.sourceImage.nativeElement.style.maxHeight = '80vh';
        this.imageCropper.sourceImage.nativeElement.style.maxWidth = '80vw';
    }
}
