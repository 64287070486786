import {Component, OnInit} from '@angular/core';
import { StreamingSettingsHttpModel } from '../../../bundles/settings/models/StreamingSettingsHttpModel';
import { StreamingSettingsHttpService } from '../../../bundles/settings/services/streaming-settings-http.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-streaming-page',
    templateUrl: './streaming-page.component.html',
    styleUrls: ['./streaming-page.component.scss']
})
export class StreamingPageComponent implements OnInit {

    safeSrcStreaming: SafeResourceUrl;
    safeSrcChat: SafeResourceUrl;

    constructor(private settingsService: StreamingSettingsHttpService, private sanitizer: DomSanitizer) {
    }

    private _settings = new StreamingSettingsHttpModel();

    get settings(): StreamingSettingsHttpModel {
        return this._settings;
    }

    set settings(value: StreamingSettingsHttpModel) {
        this._settings = value;
    }

    private _loading = false;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }


    ngOnInit() {
        this.loading =true;
        this.settingsService.get().subscribe(
            (ok) => {
                this.settings = ok;
                this.safeSrcStreaming =  this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.streamingCode);
                this.safeSrcChat =  this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.chatCode);
                this.loading = false;
            },
            (ko) => {
                this.loading = false;
                throw ko;
            }
        )
    }
}
