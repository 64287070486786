/* Import module definition */
import {NgModule} from '@angular/core';
/* Import routes modules */
import {RouterModule, Routes} from '@angular/router';
import { AuthorizationGuard } from 'src/app/middleware/guards/authorization.guard';
import { StreamingSettingsDetailPageComponent } from './bundles/settings/components/detail-page/settings-detail-page.component';
/* Import List page component */
import {StreamingPageComponent} from './components/pages/streaming-page/streaming-page.component';

/**
 * Route management definition
 *
 * [
 * {path: '', component: ObjectComponent},
 * {path: 'detail', component: ObjectComponent, children: {path: ':id', component: ObjectChildComponent}}
 * {path: '**', component: NotFoundErrorComponent},
 * ]
 */
const routes: Routes = [
    {
        path: '',
        component: StreamingPageComponent
    },
    {
        path: 'settings',
        canActivate: [AuthorizationGuard],
        component: StreamingSettingsDetailPageComponent
    },
    {
        path: 'settings/edit',
        canActivate: [AuthorizationGuard],
        component: StreamingSettingsDetailPageComponent
    },
];

/**
 * Module definition
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

/**
 * Routing component declaration for Event
 */
export class StreamingRoutingModule {
}
