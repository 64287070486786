import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {passwordMatchValidator} from './validators/password-match-validator';

@Component({
    selector: 'app-password-update-form',
    templateUrl: './password-update-form.component.html',
    styleUrls: ['./password-update-form.component.scss']
})
export class PasswordUpdateFormComponent implements OnInit {

    passwordUpdateForm: FormGroup;
    @Output() sendPassword = new EventEmitter<string>();

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.passwordUpdateForm = this.formBuilder.group(
            {
                password: ['', [Validators.minLength(8), Validators.required]],
                confirmPassword: [{value: '', disabled: true}, [Validators.required]]
            },
            {validators: passwordMatchValidator()}
        );
    }

    enableConfirm(value: string) {
        if (value.length >= 8) {
            this.passwordUpdateForm.controls.confirmPassword.enable();
        } else {
            this.passwordUpdateForm.controls.confirmPassword.disable();
        }
    }

    onSubmit() {
        if (this.passwordUpdateForm.valid) {
            this.sendPassword.emit(this.passwordUpdateForm.controls.password.value);
        }
    }
}
