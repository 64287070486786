import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {concatMap, delay, exhaustMap, retryWhen, take} from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.authService.session.pipe(
            take(1),
            exhaustMap(session => {
                let clonedRequest = req;
                if (session) {
                    clonedRequest = req.clone({
                        headers: req.headers.set('Authorization', `Bearer ${session.token}`)
                    });
                }
                return next.handle(clonedRequest).pipe(
                    // Retry two times with 200ms delay if we get an error response,
                    // Throw the original error if there is not a successful response after the third try
                    retryWhen((errors) => {
                        return errors.pipe(
                            concatMap((e, i) => {
                                if (i >= 2 || (e instanceof HttpErrorResponse && e.status === 409)) {
                                    return throwError(e);
                                }
                                return of(e).pipe(delay(200));
                            })
                        );
                    })
                );
            })
        );
    }

}
