<div class="card">
    <div class="card-header" i18n>
        Filtrar usuarios
    </div>

    <div class="card-body">
        <div class="row">
            <!-- Name Filters -->
            <div class="col-md-12 form-group">
                <label i18n>Usuario</label>
                <mat-form-field class="w-100" floatLabel='never'>
                    <input type="text" placeholder="" matInput [formControl]="userFormControl"
                           [matAutocomplete]="auto1">
                    <mat-autocomplete #auto1="matAutocomplete"
                                      (optionSelected)="onFilter()"
                                      [displayWith]="displayName">
                        <mat-option *ngFor="let user of filteredUsers | async"
                                    [value]="user">
                            {{user.name}} {{user.lastName}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error i18n>Seleccione una opción de la lista</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="card-footer">
        <button (click)="onFilter()" class="btn btn-sm btn-primary mr-2" type="submit">
            <i class="fa fa-filter"></i>&nbsp;<ng-container i18n>Filtrar usuarios</ng-container>
        </button>
        <button (click)="clearFilters()" class="btn btn-sm btn-secondary" type="reset">
            <i class="fa fa-undo"></i>&nbsp;<ng-container i18n>Limpiar filtros</ng-container>
        </button>
    </div>
</div>
