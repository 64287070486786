<div class="modal-custom">
    <div class="modal-icon text-primary mb-20">
        <i class="material-icons">
            error_outline
        </i>
    </div>
    <div mat-dialog-title class="text-center" i18n>Confirmar acción</div>
    <div mat-dialog-content class="text-center">
        {{data}}
    </div>
    <div class="d-flex justify-content-center flex-wrap mt-2">
        <button (click)="onNoClick()" class="mx-2 btn btn-secondary"><span i18n>Cancelar</span></button>
        <button (click)="onConfirm()" class="mx-2 btn btn-primary"><span i18n>Borrar</span></button>
    </div>
</div>
