import { Location } from '@angular/common';
import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router} from '@angular/router';
import { StreamingSettingsHttpModel } from '../../models/StreamingSettingsHttpModel';
import { StreamingSettingsHttpService } from '../../services/streaming-settings-http.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

interface FormProperty {
    name: string;
    type: string;
    label: string;
    placeholder: string;
    defaultValue: string;
    editable: boolean;
    value: any;
    required: boolean;
    minValue?: number;
    patternValidation?: string;
    selectable?: boolean;
    dataSource?: string;
    autocomplete?: boolean;
    autocompleteOptions?: string;
    autocompleteInitValue?: string;
    wrapperClass?: string;
    valueChanges?: () => void;
}

@Component({
    selector: 'app-settings-detail-page',
    templateUrl: './settings-detail-page.component.html',
    styleUrls: ['./settings-detail-page.component.scss']
})
export class StreamingSettingsDetailPageComponent implements OnInit {

    editable: boolean;
    Editor = ClassicEditor;

    constructor(private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private settingsService: StreamingSettingsHttpService) {

    }

    private _properties: FormProperty[] = [
        {
            name: 'streamingCode',
            type: 'string',
            label: 'Video URL',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-12'
        },
        {
            name: 'chatCode',
            type: 'string',
            label: 'Chat URL',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-12'
        },
        {
            name: 'showChat',
            type: 'boolean',
            label: 'Mostrat Chat',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: false,
            wrapperClass: 'col-md-12'
        },
        {
            name: 'title',
            type: 'string',
            label: 'Titulo',
            placeholder: '',
            defaultValue: '',
            editable: true,
            value: '',
            required: true,
            wrapperClass: 'col-md-12'
        }
    ];

    get properties(): FormProperty[] {
        return this._properties;
    }

    set properties(value: FormProperty[]) {
        this._properties = value;
    }

    private _model = new StreamingSettingsHttpModel();

    get model(): StreamingSettingsHttpModel {
        return this._model;
    }

    set model(value: StreamingSettingsHttpModel) {
        this._model = value;
    }

    private _loading = false;

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    private _formGroup: FormGroup;

    get formGroup(): FormGroup {
        return this._formGroup;
    }

    set formGroup(value: FormGroup) {
        this._formGroup = value;
    }

    ngOnInit() {
        this.loadModel();
        this.initFormGroup();

        /* if the url has the last segment 'edit' set editing to true */
        this.route.url.subscribe(segments => {
            this.editable = segments.pop().path === 'edit';
            this.updatePropertiesState();
        });
    }

    /**
     * Load model information
     */
    loadModel() {
        this.loading = true;
        this.settingsService.get().subscribe(
            (ok) => {
                this.loading = false;
                this.model = ok;
                for (const property of this.properties) {
                    this.formGroup.controls[property.name].setValue(this.model[property.name]);
                }
            },
            (ko) => {
                this.loading = false;
                this.snackBar.open($localize`Ha ocurrido un error cargando los datos.`, $localize`Aceptar`, {duration: 5000});
                throw ko;
            }
        );
    }

    /**
     * Init form group
     */
    initFormGroup() {
        const formControls = {};
        for (const property of this.properties) {
            formControls[property.name] = [{
                value: property.defaultValue,
                disabled: !property.editable
            }, []];

            // Add validator if required
            if (property.required) {
                formControls[property.name][1].push(Validators.required);
            }

            // Add email validator
            if (property.type === 'email') {
                formControls[property.name][1].push(Validators.email);
            }

            // Add number validator
            if (property.type === 'number') {
                formControls[property.name][1].push(Validators.min(property.minValue));
            }

            // Add pattern validator if string
            if (property.type === 'string' && property.patternValidation) {
                formControls[property.name][1].push(Validators.pattern(property.patternValidation));
            }
        }

        this.formGroup = this.formBuilder.group(formControls);

        for (const property of this.properties) {
            this.formGroup.get(property.name).valueChanges.subscribe(value => {
                this.model[property.name] = value;

                if (property.valueChanges) {
                    property.valueChanges();
                }
            });
        }

        console.log(this.formGroup)
    }


    saveModel() {
        this.loading = true;
        this.settingsService.edit(this.model).subscribe(
            (ok) => {
                this.loading = false;
                this.snackBar.open($localize`La configuración se ha guardado correctamente.`, $localize`Aceptar`, {duration: 5000});
                this.router.navigate([`/streaming`]);
            },
            (ko) => {
                this.snackBar.open($localize`Ha ocurrido un error guardando la configuración.`, $localize`Aceptar`, {duration: 5000});
                this.loading = false;
            }
        );
    }


    onBack() {
        this.location.back();
    }

    setEditing() {
        this.router.navigate(["/streaming/settings/edit"]);
    }

    private updatePropertiesState() {
        this.editable ? this.formGroup.enable() : this.formGroup.disable();
        this.properties.forEach(property => {
            if (!property.editable) {
                this.formGroup.get(property.name).disable();
                return;
            }
            property.editable = this.editable;
        });
    }

    onChangeEditor( { editor }: ChangeEvent ) {
        this.model.description = editor.getData();
    }
}
