import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {UserHttpModel} from '../../models/http/UserHttpModel';
import {UserListHttpModel} from '../../models/http/UserListHttpModel';
import {UserHttpMapper} from '../../mappers/UserHttpMapper';
import {Observable} from 'rxjs';
import {UserQueryParameters} from '../../models/user-query-parameters';
import {Router, UrlSerializer} from '@angular/router';
import {SessionHttpModel} from '../../../session/models/http/SessionHttpModel';

@Injectable({
    providedIn: 'root'
})
export class UserHttpService {

    /**
     * Added HttpClient to make http requests
     *
     * @param httpClient
     * @param router
     * @param serializer
     */
    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private serializer: UrlSerializer
    ) {
    }

    /**
     * Entity url for REST
     */
    private _entityUrl = 'user';

    // region REST methods

    get entityUrl(): string {
        return this._entityUrl;
    }

    set entityUrl(value: string) {
        this._entityUrl = value;
    }

    /**
     * @param queryParameters query params like page, pageSize, name and lastName
     */
    getList(queryParameters?: UserQueryParameters): Observable<UserListHttpModel> {
        if (!queryParameters) { queryParameters = {page: 0, size: 10}; }
        const tree = this.router.createUrlTree([this.entityUrl], {queryParams: queryParameters});
        return this.httpClient.get(environment.apiUrl + this.serializer.serialize(tree)).pipe(
            map(
                response => {
                    return UserHttpMapper.mapList(response);
                }
            )
        );
    }

    /**
     * Return Observable<UserHttpModel>
     *
     * @param id string|number
     * @return Observable<UserHttpModel>
     */
    getDetail(id): Observable<UserHttpModel> {

        /**
         * Make request to backend from User and return data mapper on UserHttpModel
         */
        return this.httpClient.get(environment.apiUrl + '/' + this.entityUrl + '/' + id).pipe(
            map(
                response => {
                    return UserHttpMapper.map(response);
                }
            )
        );
    }

    /**
     *
     * @param userHttpModel
     * @return Observable<UserHttpModel>
     */
    create(userHttpModel: UserHttpModel): Observable<UserHttpModel> {
        /**
         * Parse model to body json
         */
        const keys = Object.keys(userHttpModel);
        const values = Object.values(userHttpModel);
        const body = {};

        for (let i = 0; i < keys.length; i++) {
            body[keys[i].substr(1)] = values[i];
        }

        // Here can be added custom modifications over the http body
        return this.httpClient.post<UserHttpModel>(
            environment.apiUrl + '/' + this.entityUrl,
            body
        ).pipe(
            map(response => {
                return UserHttpMapper.map(response);
            })
        );
    }

    // endregion REST methods

    /**
     *
     * @param userHttpModel
     * @return Observable<UserHttpModel>
     */
    edit(userHttpModel: UserHttpModel): Observable<UserHttpModel> {
        /**
         * Parse model to body json
         */
        const keys = Object.keys(userHttpModel);
        const values = Object.values(userHttpModel);
        const body = {};

        for (let i = 0; i < keys.length; i++) {
            body[keys[i].substr(1)] = values[i];
        }

        // Here can be added custom modifications over the http body

        return this.httpClient.put<UserHttpModel>(
            environment.apiUrl + '/' + this.entityUrl + '/' + userHttpModel.id,
            body
        ).pipe(
            map(response => {
                return UserHttpMapper.map(response);
            })
        );
    }

    /**
     *
     * @param userHttpModel
     */
    remove(userHttpModel: UserHttpModel): Observable<any> {
        return this.httpClient.delete<any>(environment.apiUrl + '/' + this.entityUrl + '/' + userHttpModel.id);
    }

    /**
     *
     * @param userHttpModel
     * @param token
     */
    setPushToken(userHttpModel: UserHttpModel, token: string) {
        return this.httpClient.put<SessionHttpModel>(
            environment.apiUrl + '/' + this.entityUrl + '/' + userHttpModel.id + '/pushToken',
            {token: token}
        );
    }

        /**
     *
     * @param userHttpModel[]
     */
    bulkUpdate(userHttpModelArray: UserHttpModel[]): Observable<any> {
        /**
         * Parse model to body json
         */
        const body = [];

        userHttpModelArray.forEach(userHttpModel => {
            const keys = Object.keys(userHttpModel);
            const values = Object.values(userHttpModel);
            const bodyElement = {};
            for (let i = 0; i < keys.length; i++) {
                bodyElement[keys[i].substr(1)] = values[i];
            }
            body.push(bodyElement);
        });
        // Here can be added custom modifications over the http body

        return this.httpClient.post<UserHttpModel>(
            environment.apiUrl + '/' + this.entityUrl + '/bulk-create',
            body
        )
    }
    
}
