import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-login-form-util',
    templateUrl: './login-form-util.component.html',
    styleUrls: ['./login-form-util.component.scss']
})
export class LoginFormUtilComponent implements OnInit {

    email: string;
    password: string;
    loading = false;

    constructor(private authService: AuthenticationService, private router: Router, private snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    onSubmit() {
        this.authService.login(this.email, this.password)
            .subscribe(
                _ => this.router.navigateByUrl('/'),
                _ => this.snackBar.open($localize`Correo electrónico o contraseña invalidos.`, $localize`Aceptar`, {duration: 5000}));
    }
}
