import {UserHttpModel} from '../../user/models/http/UserHttpModel';

export interface SessionType {
  user: UserHttpModel;
  token: string;
  iat: number;
  exp: number;
}

export class Session {

  constructor(private _session: SessionType) {
  }

  /**
   * returns the stored token or null if the token has expired
   */
  get token(): string {
    if (!this._session.exp || Date.now() > this._session.exp * 1000) {
      return null;
    }
    return this._session.token;
  }

  get iat(): number {
    return this._session.iat;
  }

  get exp(): number {
    return this._session.exp;
  }

  get session(): SessionType {
    return this._session;
  }

  get user(): UserHttpModel {
    return this._session.user;
  }
}
