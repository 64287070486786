import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserQueryParameters} from '../../../models/user-query-parameters';
import {UserDataService} from '../../../services/user-data.service';
import {UserHttpModel} from '../../../models/http/UserHttpModel';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {UserHttpService} from '../../../services/http/user-http.service';
import {objectValidator} from '../../../../../validators/object-validator';

@Component({
    selector: 'user-filters',
    templateUrl: './filters-util.component.html',
    styleUrls: ['./filters-util.component.scss']
})
export class FiltersUtilComponent implements OnInit {
    name: string;
    lastName: string;

    userFormControl = new FormControl('', [objectValidator()]);
    filteredUsers: Observable<UserHttpModel[]>;

    @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() queryParameters: UserQueryParameters;

    constructor(
        private userHttpService: UserHttpService
    ) {
    }

    ngOnInit() {
        this.filteredUsers = this.userFormControl.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            switchMap(value =>
                this.userHttpService.getList({size: 0, page: 0, nameLastName: value}).pipe(
                    map(response => response.userList)
                )
            )
        );
    }

    displayName(user: UserHttpModel) {
        return user ? `${user.name} ${user.lastName}` : '';
    }

    onFilter() {
        if (this.userFormControl.valid) {
            this.queryParameters.page = 0;
            this.queryParameters.name = this.userFormControl.value.name;
            this.queryParameters.lastName = this.userFormControl.value.lastName;

            if (!this.allFilterParametersEmpty()) {
                this.filterChange.emit(this.queryParameters);
            }
        }
    }

    clearFilters() {
        this.name = '';
        this.lastName = '';
        this.queryParameters.page = 0;
        this.queryParameters.name = this.name;
        this.queryParameters.lastName = this.lastName;
        this.userFormControl.setValue('');

        this.filterChange.emit(this.queryParameters);
    }

    allFilterParametersEmpty() {
        const excluded = ['page', 'size'];
        let empty = true;

        for (const parameter in this.queryParameters) {
            if (this.queryParameters.hasOwnProperty(parameter)) {
                if (excluded.indexOf(parameter) !== -1) {
                    continue;
                }

                if (!this.isEmpty(this.queryParameters[parameter])) {
                    empty = false;
                    break;
                }
            }
        }

        if (this.userFormControl.value && this.userFormControl.valid) {
            empty = false;
        }
        return empty;
    }

    isEmpty(val: any) {
        return (!val || 0 === val.length);
    }
}
