<div class="modal-custom">
    <div class="text-center" mat-dialog-title i18n>
        Seleccione un idioma
    </div>
    <div mat-dialog-content>
        <mat-action-list>
            <button mat-list-item *ngFor="let language of languages" (click)="onChangeLang(language)">
<!--                <span mat-list-icon class="flag-icon flag-icon-{{language.icon}}"></span>&nbsp;-->
                {{language.name}}
            </button>
        </mat-action-list>
    </div>
</div>
