<div class="animated fadeIn">
    <div class="card">
        <div class="card-header d-flex">
            <strong i18n>Carga masiva de usuarios</strong>
        </div>

        <div class="card-body">
            <input type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".csv" />
        </div>

        <div class="card-footer">
            <ng-container>
                <button class="btn btn-sm btn-primary mr-2"  type="button" (click)="onSave()" *ngIf="this.records && this.records.length > 0"><i
                    class="icon-check icons"></i>
                    Guardar
                </button>
            </ng-container>
        </div>
    </div>
</div>
