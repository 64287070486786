import {Injectable} from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Session, SessionType} from '../bundles/login/models/SessionModel';
import {Router} from '@angular/router';
import {LoginHttpService} from '../bundles/login/services/http/login-http.service';
import {TokenType} from '../bundles/login/models/TokenType';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    session = new BehaviorSubject<Session>(null);

    constructor(private loginHttpService: LoginHttpService,
                private router: Router) {
    }

    login(email: string, password: string): Observable<Session> {
        return this.loginHttpService.login(email, password).pipe(
            switchMap(response => this.setSession(response)),
            tap(sessionData => {
                this.session.next(sessionData);
                localStorage.setItem('session', JSON.stringify(sessionData.session));
            }));
    }

    autoLogin() {
        const sessionData: SessionType = JSON.parse(localStorage.getItem('session'));
        if (sessionData && sessionData.token) {
            const session = new Session(sessionData);
            if (session.token) { // if the token has expired it returns null
                // Set the session gotten from localStorage directly to use the token to communicate with the backend
                this.session.next(session);
            } else {
                this.logout();
            }
        }
    }

    logout(): void {
        if (this.session.getValue()) {
            this.loginHttpService.logout(this.session.getValue().token).subscribe();
            this.session.next(null);
        }
        localStorage.removeItem('session');
        this.router.navigate(['login']);
    }

    /**
     * Calls the API to send a email to the indicated address to get a password recovery link
     * @param email where the recovery link will be sent
     */
    passwordRecovery(email: string): Observable<any> {
        return this.loginHttpService.recovery(email);
    }

    updatePassword(password: string, recoveryToken: string): Observable<any> {
        return this.loginHttpService.updatePassword(password, recoveryToken);
    }

    private setSession(response: TokenType): Observable<Session> {
        const data = jwtDecode(response.token) as SessionType;
        data.token = response.token;

        const session = new Session(data);
        this.session.next(session);
        return of(session);

    }

}
