<form [formGroup]="passwordUpdateForm">
    <h1 i18n>Nueva contraseña</h1>
    <p class="text-muted" i18n>Cambia tu contraseña.</p>
    <div class="input-group mb-4 mt-4">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="icon-lock"></i></span>
        </div>
        <mat-form-field floatLabel='never'>
            <input (ngModelChange)="enableConfirm($event)" type="password" matInput formControlName="password" placeholder="Nueva contraseña"/>
            <mat-error i18n>La contraseña debe tener más de 8 caracteres</mat-error>
        </mat-form-field>
    </div>

    <div class="input-group mb-4">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="icon-lock"></i></span>
        </div>
        <mat-form-field floatLabel='never'>
            <input type="password" matInput formControlName="confirmPassword" i18n-placeholder placeholder="Confirmar contraseña"/>
            <mat-error i18n>Las contraseñas no coinciden</mat-error>
        </mat-form-field>
    </div>

    <div class="row">
      <div class="col-12">
        <button [disabled]="!passwordUpdateForm.valid" type="submit" class="w-100 btn btn-primary px-4" (click)="onSubmit()" i18n>Cambiar contraseña</button>
      </div>
    </div>
</form>
