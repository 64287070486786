import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {UserModule} from '../bundles/user/user.module';


@NgModule({
    declarations: [AppLayoutComponent],
    imports: [
        CommonModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule,
        RouterModule,
        AppFooterModule,
        MatButtonModule,
        UserModule
    ]
})
export class LayoutsModule {
}
