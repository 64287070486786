import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../services/authentication.service';
import {take, tap} from 'rxjs/operators';
import {NotificationService} from '../../services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanLoad {

    constructor(private authService: AuthenticationService,
                private router: Router,
                private notifier: NotificationService) {
    }

    // CanActivate is called when accessing a specific route
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            const session = this.authService.session.getValue();
            if (!session || !session.token) {
                this.authService.logout();
                return this.router.navigate(['/login']);
            }
            if(!session.user.isAdmin)
                return this.router.navigate(['/streaming']);

            return true;
    }
    // CanLoad will prevent loading a module for lazy loaded modules -> 'loadChildren: ...'
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
            return true;
    }
}
