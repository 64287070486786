<form>
    <h1 i18n class="text-center text-page-title-color mt-5">Iniciar sesión</h1>
    <p class="text-muted text-center mt-5" i18n>Inicia sesión en Oesia video streaming.</p>
    <div class="input-group mb-3 mt-5">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="icon-envelope"></i></span>
        </div>
        <input [(ngModel)]="email" autocomplete="email" class="form-control" name="email" placeholder="Email"
               required type="email">
    </div>

    <div class="input-group mb-4">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="icon-lock"></i></span>
        </div>
        <input [(ngModel)]="password" autocomplete="password" class="form-control" name="password"
               i18n-placeholder
               placeholder="Contraseña"
               required type="password">
    </div>

    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!loading" class="row mt-5">
        <div class="col-12 col-xl-5 btn-login mt-5">
            <button (click)="onSubmit()" class="btn btn-primary px-4 btn-block" type="submit" i18n>Entrar</button>
        </div>

        <!-- <div class="col-12 col-xl-7 text-right">
            <button class="btn btn-link px-0" routerLink="/login/recovery" type="button" i18n>Recuperar contraseña</button>
        </div> -->
    </div>
</form>
