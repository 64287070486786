import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

interface Language {
    code: string;
    name: string;
    icon: string;
}

@Component({
    selector: 'app-language-selector-dialog',
    templateUrl: './language-selector-dialog.component.html',
    styleUrls: ['./language-selector-dialog.component.scss']
})
export class LanguageSelectorDialogComponent implements OnInit {
    languages: Language[] = [
        {
            code: 'es',
            name: 'Castellano',
            icon: 'es'
        },
        {
            code: 'eu',
            name: 'Euskera',
            icon: 'es-eus'
        },
        {
            code: 'en',
            name: 'English',
            icon: 'gb'
        }
    ];

    constructor(public dialogRef: MatDialogRef<LanguageSelectorDialogComponent>) {
    }

    ngOnInit(): void {
    }

    onChangeLang(language: Language) {
        window.location.href = `/${language.code}`;
    }
}
