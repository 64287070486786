import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-recovery-form-util',
    templateUrl: './recovery-form-util.component.html',
    styleUrls: ['./recovery-form-util.component.scss']
})
export class RecoveryFormUtilComponent implements OnInit {
    private _email: string;
    private _error = false;
    private _loading = false;

    constructor(private authenticationService: AuthenticationService,
                private location: Location,
                private snackBar: MatSnackBar) {
    }

    ngOnInit() {
    }

    /**
     *
     */
    onSubmit() {
        this.loading = true;
        this.authenticationService.passwordRecovery(this.email)
            .subscribe(
                (next) => {
                    this.snackBar.open(
                        $localize`Se le ha enviado un correo para la recuperación de contraseña`,
                        $localize`Aceptar`, {duration: 5000});
                    this.loading = false;
                    this.location.back();
                },
                (error) => {
                    this.snackBar.open(
                        $localize`Ocurrió un error. ¿Está seguro que ha escrito bien el correo electrónico?`,
                        $localize`Aceptar`, {duration: 5000});
                    this.error = true;
                    this.loading = false;
                    throw error;
                });
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get error(): boolean {
        return this._error;
    }

    set error(value: boolean) {
        this._error = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }
}
