import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getSpanishPaginatorIntl} from './providers/spanish-paginator-intl';
import {ConfirmationDialogComponent} from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
    declarations:
        [
            ConfirmationDialogComponent
        ],
    imports: [
        CommonModule,
        MatDialogModule
    ],
    exports: [],
    providers: [
        {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()}
    ]
})
export class MiddlewareModule {
}
