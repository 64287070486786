import {Injectable} from '@angular/core';
import {UserQueryParameters} from '../models/user-query-parameters';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  userParameters: UserQueryParameters;

  constructor() { }

  set user(user: UserQueryParameters) {
    this.userParameters = user;
  }

  get user(): UserQueryParameters {
    return this.userParameters;
  }
}
