import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 *  Checks that the two passwords match
 */
export function passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.get('password').value !== control.get('confirmPassword').value) {
            control.get('confirmPassword').setErrors({PasswordsDontMatch: true});
            return {PasswordsDontMatch: true};
        } else {
            control.get('confirmPassword').setErrors(null);
            return null;
        }
    };
}
