import {AbstractControl, ValidatorFn} from '@angular/forms';

/**
 *  Checks that the user has selected a valid element in the field or has left it empty
 */
export function objectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return !(typeof control.value === 'object' || !control.value) ? {IllegalValue: {value: control.value}} : null;
    };
}
