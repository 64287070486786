import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {TokenType} from '../../models/TokenType';

@Injectable({
    providedIn: 'root'
})
export class LoginHttpService {

    constructor(private httpClient: HttpClient) {
    }

    login(email: string, password: string): Observable<TokenType> {
        return this.httpClient.post<TokenType>(`${environment.apiUrl}/login`, {email, password});
    }

    recovery(email: string): Observable<any> {
        return this.httpClient.post(`${environment.apiUrl}/login/recovery`, {email});
    }

    updatePassword(password: string, recoveryToken: string) {
        return this.httpClient.post(`${environment.apiUrl}/login/recovery/${recoveryToken}`, {password});
    }

    logout(token: string) {
        return this.httpClient.post(`${environment.apiUrl}/logout`, {token});
    }
}
