

<header class="header-basic-light">

	<div class="header-limiter">

        <a [routerLink]="['/streaming']">
            <img src="assets/img/logo-red.png" width="30" height="30" alt="">
        </a>

		<nav>
            <div class="nav navbar-nav ml-auto">
                <app-user-nav-util></app-user-nav-util>
            </div>
		</nav>
	</div>

</header>


<div class="app-body" style="background-repeat: no-repeat; background-size: cover; background-image: url('assets/img/oesia.jpg');">
    <app-sidebar #appSidebar (minimizedChange)="toggleMinimize($event)" [display]="'lg'" [fixed]="true"
                 [minimized]="sidebarMinimized">
        <app-sidebar-nav [disabled]="appSidebar.minimized" 
                         [perfectScrollbar]></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>

    
    <!-- Main content -->
    <main class="main ml-0">
        <div class="container-fluid mt-0">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>

<app-footer class="ml-0">
    <span class="margin-left-custom footer-mobile">&copy; {{ cYear }} ©GRUPO OESÍA</span>
</app-footer>
