import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-password-update-page',
    templateUrl: './password-update-page.component.html',
    styleUrls: ['./password-update-page.component.scss']
})
export class PasswordUpdatePageComponent implements OnInit {

    recoveryToken: string;

    constructor(private authService: AuthenticationService,
                private route: ActivatedRoute,
                private snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit() {
        this.recoveryToken = this.route.snapshot.params.token;
    }

    onSendNewPassword(password: string) {
        this.authService.updatePassword(password, this.recoveryToken).subscribe(
            _ => {
                this.snackBar.open('Tu contraseña ha sido actualizada con éxito', $localize`Aceptar`, {duration: 5000});
                this.router.navigate(['login']);
            });
    }
}
