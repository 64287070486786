import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StreamingRoutingModule} from './streaming-routing.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {MiddlewareModule} from '../../middleware/middleware.module';
import {MatSelectModule} from '@angular/material/select';
import {StreamingPageComponent} from './components/pages/streaming-page/streaming-page.component';
import { StreamingSettingsDetailPageComponent } from './bundles/settings/components/detail-page/settings-detail-page.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';

/**
 * Declare Module to import all needed modules, components, providers...
 *
 * Declarations: Components
 * Imports: Modules needed
 * Providers: Services used on start up
 * Entry components: Modals mainly
 * schemas: core schema
 */
@NgModule({
    declarations: [
        StreamingPageComponent,
        StreamingSettingsDetailPageComponent
    ],
    imports: [
        CommonModule,
        StreamingRoutingModule,
        HttpClientModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        MiddlewareModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        CKEditorModule,
        MatCheckboxModule
    ],
    providers: [
        DatePipe,
    ],
    entryComponents: [

    ],
    exports: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

/**
 * Event module declaration
 */
export class StreamingModule {
}
