/* NgModule import to be able to declare it as module */
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
/* Common module import */
import {CommonModule, DatePipe} from '@angular/common';
/* Http modules import */
import {HttpClientModule} from '@angular/common/http';
/* Routing module import */
import {UserRoutingModule} from './user-routing.module';
import {UserPageComponent} from './components/pages/list-page/user-list-page.component';
import {UserDetailPageComponent} from './components/pages/detail-page/user-detail-page.component';
import {UserCreatePageComponent} from './components/pages/create-page/user-create-page.component';
import {UserTableListUtilComponent} from './components/utils/table-list-util/user-table-list-util.component';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {CreateFormUtilComponent} from './components/utils/create-form-util/create-form-util.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DetailFormUtilComponent} from './components/utils/detail-form-util/detail-form-util.component';
import {MiddlewareModule} from '../../middleware/middleware.module';
import {FiltersUtilComponent} from './components/utils/filters-util/filters-util.component';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DetailCardUtilComponent} from './components/utils/detail-card-util/detail-card-util.component';
import {UserEditPageComponent} from './components/pages/edit-page/user-edit-page.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from './dialogs/confirmation-dialog/confirmation-dialog.component';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ProfilePictureUtilComponent} from './components/utils/profile-picture-util/profile-picture-util.component';
import {MatDividerModule} from '@angular/material/divider';
import {ProfilePictureCropperDialogComponent} from './dialogs/profile-picture-cropper-dialog/profile-picture-cropper-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { NavUtilComponent } from './components/utils/nav-util/nav-util.component';
import { LanguageSelectorDialogComponent } from './dialogs/language-selector-dialog/language-selector-dialog.component';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { UserBulkCreatePageComponent } from './components/pages/bulk-create/user-bulk-create-page.component';

/**
 * Declare Module to import all needed modules, components, providers...
 *
 * Declarations: Components
 * Imports: Modules needed
 * Providers: Services used on start up
 * Entry components: Modals mainly
 * schemas: core schema
 */
@NgModule({
    declarations: [
        UserPageComponent,
        UserDetailPageComponent,
        UserCreatePageComponent,
        UserTableListUtilComponent,
        UserBulkCreatePageComponent,
        CreateFormUtilComponent,
        DetailFormUtilComponent,
        FiltersUtilComponent,
        DetailCardUtilComponent,
        UserEditPageComponent,
        ConfirmationDialogComponent,
        ProfilePictureUtilComponent,
        ProfilePictureCropperDialogComponent,
        NavUtilComponent,
        LanguageSelectorDialogComponent
    ],
    imports: [
        CommonModule,
        UserRoutingModule,
        HttpClientModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MiddlewareModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        BsDropdownModule.forRoot(),
        TabsModule,
        MatDialogModule,
        MatDividerModule,
        ImageCropperModule,
        MatListModule,
        MatCheckboxModule
    ],
    providers: [
        DatePipe
    ],
    entryComponents: [
        ConfirmationDialogComponent
    ],
    exports: [
        NavUtilComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

/**
 * User module declaration
 */
export class UserModule {
}
