import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './components/pages/login-page/login-page.component';
import {RecoveryPageComponent} from './components/pages/recovery-page/recovery-page.component';
import {PasswordUpdatePageComponent} from './components/pages/password-update-page/password-update-page.component';

const routes: Routes = [
    {
        path: '',
        component: LoginPageComponent
    },
    {
        path: 'recovery',
        component: RecoveryPageComponent
    },
    {
        path: 'recovery/:token',
        component: PasswordUpdatePageComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule {
}
