import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-list-page',
    templateUrl: './user-list-page.component.html',
    styleUrls: ['./user-list-page.component.scss']
})
export class UserPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
