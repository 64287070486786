import {Component, OnInit} from '@angular/core';
import {UserHttpService} from "../../../services/http/user-http.service";

@Component({
    selector: 'app-user-create-page',
    templateUrl: './user-create-page.component.html',
    styleUrls: ['./user-create-page.component.scss']
})
export class UserCreatePageComponent implements OnInit {

    constructor(private userHttpService: UserHttpService) {
    }

    ngOnInit() {
        /**
         * @todo
         */
    }

    /**
     * This method evaluates if everything was loaded, so components can be printed
     */
    isLoaded() {
        /**
         * @todo
         */
    }
}
