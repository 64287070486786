import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from './middleware/guards/authenticated.guard';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {LoginLayoutComponent} from './bundles/login/layouts/login-layout/login-layout.component';
import { AuthorizationGuard } from './middleware/guards/authorization.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthenticatedGuard],
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'streaming',
                pathMatch: 'full'
            },
            {
                path: 'user',
                canActivate: [AuthorizationGuard],
                loadChildren: () => import('./bundles/user/user.module').then(m => m.UserModule)
            },
            {
                path: 'streaming',
                loadChildren: () => import('./bundles/streaming/streaming.module').then(m => m.StreamingModule)
            },
        ]
    },
    {
        path: 'login',
        component: LoginLayoutComponent,
        loadChildren: () => import('./bundles/login/login.module').then(m => m.LoginModule)
    },
    {path: '**', redirectTo: 'streaming'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
