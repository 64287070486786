import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
// tslint:disable-next-line:max-line-length
import {ProfilePictureCropperDialogComponent} from '../../../dialogs/profile-picture-cropper-dialog/profile-picture-cropper-dialog.component';

@Component({
    selector: 'app-profile-picture-util',
    templateUrl: './profile-picture-util.component.html',
    styleUrls: ['./profile-picture-util.component.scss']
})
export class ProfilePictureUtilComponent implements OnInit {

    environment = environment;

    imageUrl: string;
    loading = true;

    @Input() id: string;
    @Input() editing: boolean;
    @Output() imageSelected = new EventEmitter<File>();

    constructor(private snackBar: MatSnackBar,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.imageUrl = `${environment.apiUrl}/user/${this.id}/profile-picture`;
    }

    onLoad() {
        this.loading = false;
    }

    changeUrl($event: Event) {
        const fileInput: HTMLInputElement = $event.currentTarget as HTMLInputElement;
        if (fileInput.files.length > 0) {
            if (fileInput.files[0].type.match(/image\/*/) == null) {
                this.snackBar.open($localize`Solo se admiten imágenes.`, $localize`Aceptar`, {duration: 5000});
                return;
            }
        }
        const dialogRef = this.dialog.open(ProfilePictureCropperDialogComponent,
            {
                data: {
                    imageChangedEvent: $event,
                    imageName: fileInput.files[0].name,
                    type: fileInput.files[0].type
                },
                width: 'fit-content', height: 'fit-content'
            });
        dialogRef.afterClosed().subscribe(image => {
            if (image) {
                this.imageSelected.emit(image);
                this.setImagePreview(image);
            }
        });
    }

    private setImagePreview(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.imageUrl = reader.result as string;
        };
    }
}
