import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationService} from '../../services/notification.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private authService: AuthenticationService;
    private router: Router;
    private notifier: NotificationService;

    constructor(private injector: Injector) {
    }

    handleError(error: Error | HttpErrorResponse) {
        this.notifier = this.injector.get(NotificationService);
        this.router = this.injector.get(Router);
        this.authService = this.injector.get(AuthenticationService);

        if (error instanceof HttpErrorResponse && error.status === 0) {
            // notifier.showError('Se ha perdido la conexión con el servidor');
        }

        if (error instanceof HttpErrorResponse && error.status === 401) {
            this.notifier.showError($localize`:@@errorauthe:Error de autentificación, por favor vuelva a iniciar sesión`);
            this.authService.logout();
        }

        if (error instanceof HttpErrorResponse && error.status === 403) {
            this.notifier.showError($localize`:@@errorautho:Error de autorización, no está autorizado para acceder al recurso`);
            this.router.navigate(['/user']);
        }

        console.error(error);
    }
}
