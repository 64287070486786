<div class="app">
    <div class="app-body">
        <main class="main d-flex align-items-center" style="background-repeat: no-repeat; background-size: cover; background-image: url('assets/img/oesia.jpg');">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mx-auto">
                        <div class="login">

                            <div class="card card-login-logo text-white bg-primary py-5 d-md-down-none" style="width:44%">
                                <div class="card-body d-flex justify-content-center align-items-center">
                                    <div>
                                        <img class="logo-mobile" alt="Fhimasa" src="../../../../../assets/img/logo-white.png">
                                    </div>
                                </div>
                            </div>

                            <div class="card card-login-login p-4">
                                <div class="card-body">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
